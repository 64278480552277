import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { GetSummaryParams } from './index';
import { getManipulationsSummaryThunk } from './thunks/getManipulationsSummary';

interface ManipulationSummary {
  dateTime: string;
  isDay: boolean;
  total: number;
  manipulations: {
    [manipulation: string]: number;
  };
  beds: string[];
}

export interface ManipulationsSummary {
  searchCriteria: Omit<GetSummaryParams, 'limit' | 'offset'>;
  hospital: string;
  manipulationNames: string[];
  dataAccurate: boolean;
  offset: number;
  limit: number;
  totalCount: number;
  summaries: ManipulationSummary[];

  keepExisting?: boolean;
}

export const initialState: ManipulationsSummary = {
  dataAccurate: false,
  hospital: '',
  limit: 25,
  manipulationNames: [],
  offset: 0,
  summaries: [],
  totalCount: 0,
  searchCriteria: {
    hospital: null,
    dateFrom: null,
    dateTo: null,
  },
};

export const manipulationSummarySlice = createSlice({
  name: 'manipulationSummary',
  initialState,
  reducers: {
    resetManipulationSummary: (state) => {
      state = initialState;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getManipulationsSummaryThunk.fulfilled,
      (state, { payload: { violations, manipulationSummary } }) => {
        if (!violations.length) {
          if (!manipulationSummary.keepExisting) {
            state = manipulationSummary;
          } else {
            state = {
              ...manipulationSummary,
              summaries: [...state.summaries, ...manipulationSummary.summaries],
            };
          }

          return state;
        }
      },
    );
  },
});

export const selectManipulationSummary = (state: RootState) => state.manipulationSummary;

export const { resetManipulationSummary } = manipulationSummarySlice.actions;

export default manipulationSummarySlice.reducer;
