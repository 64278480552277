import { combineReducers, configureStore } from '@reduxjs/toolkit';
import config from '../features/config/configSlice';
import displayRules from '../features/displayRules/displayRulesSlice';
import i18n from '../features/i18n/i18nSlice';
import protocol from '../features/protocol/protocolSlice';
import refdata from '../features/refdata/refdataSlice';
import protocolSummary from '../features/summary/protocolSummarySlice';
import manipulationSummary from '../features/summary/manipulationSummarySlice';
import protocols from '../features/summary/protocolsSlice';
import ui from '../features/ui/uiSlice';
import changePassword from '../features/user/changePassword/changePasswordSlice';
import login from '../features/user/login/loginSlice';
import register from '../features/user/register/registerSlice';
import user from '../features/user/userSlice';
import violations from '../features/violations/violationsSlice';

const rootReducer = combineReducers({
  config,
  displayRules,
  i18n,
  protocol,
  protocolSummary,
  manipulationSummary,
  protocols,
  refdata,
  ui,
  user,
  changePassword,
  login,
  register,
  violations,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
