import Button, { ButtonProps } from '@mui/material/Button';
import React, { FC } from 'react';
import { I18nTypes } from '../../App/types';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { I18nFragment } from './I18nFragment';

interface FormButtonProps extends ButtonProps, WithDisplayRuleProps {
  id: I18nTypes;
}

export const FormButtonComponent: FC<FormButtonProps> = ({ id, ...rest }) => (
  <Button {...rest}>
    <I18nFragment id={id} />
  </Button>
);

export const FormButton = withDisplayRule(FormButtonComponent);
