import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import React, { FC } from 'react';
import { ReferenceData } from '../../App/apiWrapper';
import { I18nTypes, RefIdType } from '../../App/types';
import { WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';

export interface FormToggleButtonGroupProps extends WithDisplayRuleProps, ToggleButtonGroupProps {
  id: I18nTypes;
  options: ReferenceData[];
  value: RefIdType;
  ariaLabeledBy: string;
  ariaDescribedBy: string;
}

export const FormToggleButtonGroup: FC<FormToggleButtonGroupProps> = ({
  options,
  value,
  ariaLabeledBy,
  ariaDescribedBy,
  onChange,
  ...rest
}) => (
  <ToggleButtonGroup
    color="primary"
    value={value}
    exclusive
    onChange={onChange}
    aria-labelledby={ariaLabeledBy}
    aria-describedby={ariaDescribedBy}
    {...rest}
  >
    {options.map(({ id, name }) => (
      <ToggleButton value={id} key={id}>
        {name}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);
