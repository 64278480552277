import React, { FC, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { useAppSelector } from '../../App/hooks';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';
import { selectRefDataList } from '../../features/refdata/selectRefDataList';
import { I18nFragment } from './I18nFragment';
import { useTranslation } from '../../features/i18n/useTranslation';

export type FormRadioGroupProps = RadioGroupProps &
  WithDisplayRuleProps & {
    inline?: boolean;
  };

export const FormRadioGroupComponent: FC<FormRadioGroupProps> = ({
  id,
  name,
  required,
  hasViolation,
  violationMessages,
  onChange,
  value,
  sx = {},
  inline,
}) => {
  const options = useAppSelector((state) => selectRefDataList(state, id));

  const helperTextItems = getHelpTextItems(violationMessages);

  const onClear = useCallback(() => {
    const event = { target: { value: null } } as unknown as React.ChangeEvent<HTMLInputElement>;
    onChange && onChange(event, event.target.value);
  }, [onChange]);

  return (
    <FormControl {...(hasViolation === true ? { error: true } : {})} required={required} sx={{ mt: 1, ...sx }}>
      <Stack direction="row" spacing={2}>
        <FormLabel id={`radio_group_label_${id}`}>{useTranslation(id)}</FormLabel>
        {!required && value && (
          <Link onClick={onClear} component="button">
            <I18nFragment id="MDNS.BTN.CLEAR" />
          </Link>
        )}
      </Stack>

      <RadioGroup
        aria-labelledby={`radio_group_label_${id}`}
        {...(hasViolation === true ? { 'aria-invalid': true } : {})}
        aria-describedby={`radio_group_helper_${id}`}
        id={`radio_group_${id}`}
        name={name}
        value={value}
        onChange={onChange}
        {...(inline ? { sx: { flexDirection: 'row' } } : {})}
      >
        {options.map(({ id, name }) => (
          <FormControlLabel value={id} key={`radio_option_${id}`} control={<Radio />} label={name} />
        ))}
      </RadioGroup>

      <FormHelperText id={`radio_group_helper_${id}`}>{helperTextItems}</FormHelperText>
    </FormControl>
  );
};

export const FormRadioGroup = withDisplayRule(FormRadioGroupComponent);
