import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTranslationMap, TranslationsMap } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { parseTranslations } from './parseTranslations';
import { initialState } from './index';

export const getTranslationMapThunk = createAsyncThunk<TranslationsMap>('i18n/translationmap', async (_) => {
  return await getTranslationMap();
});

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTranslationMapThunk.fulfilled, (state, action) => {
      state.entries = parseTranslations(action.payload.entries);
      state.initialized = true;
    });
  },
});

export const selectTranslationsInitialized = (state: RootState) => state.i18n.initialized;

export const selectTranslationEntries = (state: RootState) => state.i18n.entries;

export default i18nSlice.reducer;
