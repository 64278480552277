import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// eslint-disable-next-line no-restricted-imports
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { RefIdType } from '../../App/types';
import {
  registerUserThunk,
  selectRegister,
  setHospital,
  setMatchingPassword,
  setPassword,
  setUserName,
} from '../../features/user/register/registerSlice';
import { FormButton } from '../common/FormButton';
import { FormRdrSelect } from '../common/FormRdrSelect';
import { FormTextField } from '../common/FormTextField';
import { FormTypography } from '../common/FormTypography';
import { ViolationBanner } from '../common/ViolationBanner';
import { withPageInit } from './withPageInit';
import { FormPasswordField } from '../common/FormPasswordField';

const SignUpPageComponent = () => {
  const dispatch = useAppDispatch();
  const registerState = useAppSelector(selectRegister);

  const { userName, hospital, password, matchingPassword, violationLevel, violationId, violationArgs } = registerState;

  const doRegister = () => {
    const userDto = {
      userName,
      password,
      hospital,
      matchingPassword,
    };
    dispatch(registerUserThunk(userDto));
  };

  const handleUserNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setUserName(event.target.value));
  };

  const handleHospitalChange: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setHospital(event.target.value));
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setPassword(event.target.value));
  };

  const handleMatchingPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setMatchingPassword(event.target.value));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {violationLevel && <ViolationBanner level={violationLevel} id={violationId} args={violationArgs} />}
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <PersonAddIcon />
        </Avatar>
        <FormTypography id="MDNS.PG.SIGNUP" component="h1" variant="h5" />
        <Box sx={{ mt: 1 }}>
          <FormTextField
            id="MDNS.RGSR.USERNAME"
            margin="normal"
            fullWidth
            onChange={handleUserNameChange}
            value={userName}
          />
          <FormRdrSelect
            id="MDNS.RGSR.HOSPITAL"
            fullWidth
            name="hospital"
            onChange={handleHospitalChange}
            value={hospital}
          />
          <FormPasswordField
            id="MDNS.RGSR.PASSWORD"
            margin="normal"
            fullWidth
            onChange={handlePasswordChange}
            value={password}
          />
          <FormPasswordField
            id="MDNS.RGSR.CONFIRMPASSWORD"
            margin="normal"
            fullWidth
            onChange={handleMatchingPasswordChange}
            value={matchingPassword}
          />
          <FormButton id="MDNS.BTN.REGISTER" onClick={doRegister} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} />
        </Box>
      </Box>
    </Container>
  );
};

export const SignUpPage = withPageInit(SignUpPageComponent);
