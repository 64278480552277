import React, { FC } from 'react';
import { useAppSelector } from '../../App/hooks';
import { PrintButton } from './parts/PrintButton';
import { CountLabel } from './parts/CountLabel';
import { TabPanelPaper } from './parts/TabPanelPaper';
import { selectProtocols } from '../../features/summary/protocolsSlice';
import { ProtocolsSearch } from '../summarySearch/ProtocolsSearch';
import { ProtocolsLoadMore } from '../summarySearch/ProtocolsLoadMore';
import { ProtocolsTable } from '../summaryTable/ProtocolsTable';

export const ProtocolsContent: FC = () => {
  const { totalCount } = useAppSelector(selectProtocols);

  return (
    <>
      <ProtocolsSearch />
      <TabPanelPaper>
        <CountLabel totalCount={totalCount} />
        <ProtocolsTable />
      </TabPanelPaper>
      <ProtocolsLoadMore />
      <PrintButton totalCount={totalCount} />
    </>
  );
};
