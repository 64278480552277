import { createAsyncThunk } from '@reduxjs/toolkit';
import { DownloadSummaryParams } from '../index';
import { downloadProtocols } from '../../../App/apiWrapper';
import { prepareReportThunk } from './prepareReportThunk';

export const downloadProtocolsReportThunk = createAsyncThunk<void, DownloadSummaryParams>(
  'post/exportProtocols',
  async ({ ...input }, { dispatch }) => {
    const response = await downloadProtocols(input);
    dispatch(prepareReportThunk(response));
  },
);
