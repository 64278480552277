import { Dayjs } from 'dayjs';

export type NumberType = number | null;

export type StringType = string | null;

export type BooleanType = boolean | null;

export type RefIdType = string | null;

export type DateType = string | null;

export const defaultDateType: DateType = null;

export type DatePickerType = Dayjs | null;

export interface MapEntries<T> {
  entries: T[] | null;
}

export enum SummaruesExportType {
  EXCEL = 'EXCEL',
  WORD = 'WORD',
}

export enum ScreenRef {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  PROTOCOL = 'PROTOCOL',
  PROTOCOL_SUMMARY = 'PROTOCOL_SUMMARY',
  MANIPULATION_SUMMARY = 'MANIPULATION_SUMMARY',
  PROTOCOLS = 'PROTOCOLS',
}

export enum ActionRef {
  LOGIN = 'login',
  REGISTER = 'register',
  CHANGE_PASSWORD = 'changePassword',
  CREATE = 'create',

  VIEW = 'VIEW',

  SEARCH = 'SEARCH',
}

export enum SummaryTypes {
  summary = 'summary',
  manipulations = 'manipulations',
  protocols = 'protocols',
}

export enum ViolationLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export interface Violation {
  ruleId: I18nTypes;
  violationLevel: ViolationLevel;
  args: string[];
}

export interface FieldViolation extends Violation {
  fieldId: string;
}

export type I18nTypes =
  | 'MDNS.HEADER'
  | 'MDNS.PG.HOME'
  | 'MDNS.PG.LOGIN'
  | 'MDNS.PG.PROFILE'
  | 'MDNS.PG.SETTINGS'
  | 'MDNS.PG.LOGOUT'
  | 'MDNS.PG.SIGNUP'
  | 'MDNS.PG.PROTOCOL'
  | 'MDNS.PG.SUMMARY'
  | 'MDNS.RPRT.PRTCLSMMR.DATETIME'
  | 'MDNS.RPRT.PRTCLSMMR.BEDNO'
  | 'MDNS.RPRT.PRTCLSMMR.NURSESNOCUR'
  | 'MDNS.RPRT.PRTCLSMMR.NURSESNOREQ'
  | 'MDNS.RPRT.PRTCLSMMR.DAY'
  | 'MDNS.RPRT.PRTCLSMMR.NIGHT'
  | 'MDNS.RPRT.RECORDCOUNT'
  | 'MDNS.PG.CHANGEPASSWORD'
  | 'MDNS.LGN.HEADER'
  | 'MDNS.COMMON.THANKYOU'
  | 'MDNS.PRTCL.SUBMITTED'
  | 'MDNS.PRTCL.PATIENTTRANSFER'
  | 'MDNS.BTN.OK'
  | 'MDNS.BTN.PRINT'
  | 'MDNS.AUTH.ER.001'
  | 'MDNS.COMMON.ER.001'
  | 'MDNS.LGN.ER.001'
  | 'MDNS.LGN.ER.002'
  | 'MDNS.LGN.ER.003'
  | 'MDNS.LGN.ER.004'
  | 'MDNS.LGN.ER.005'
  | 'MDNS.CHGPWD.ER.001'
  | 'MDNS.CHGPWD.ER.002'
  | 'MDNS.CHGPWD.ER.003'
  | 'MDNS.CHGPWD.IR.001'
  | 'MDNS.RGSR.ER.001'
  | 'MDNS.RGSR.ER.003'
  | 'MDNS.RGSR.IR.001'
  | 'MDNS.PRTCLSMMR.ER.001'
  | 'maxLengthCondition'
  | 'emailFormatCondition'
  | 'minDateCondition'
  | 'numberCondition'
  | 'mandatoryCondition'
  | 'readOnlyAttributeUpdateError'
  | 'invalidSingleValueError'
  | 'invalidMultipleValuesError'
  | 'minDate'
  | 'maxDate'
  | 'invalidDate'
  | 'HISTORY_VIOLATION'
  | 'ILLEGAL_WORD'
  | 'ILLEGAL_WORD_REVERSED'
  | 'ILLEGAL_DIGEST_WORD'
  | 'ILLEGAL_DIGEST_WORD_REVERSED'
  | 'ILLEGAL_MATCH'
  | 'ALLOWED_MATCH'
  | 'ILLEGAL_CHAR'
  | 'ALLOWED_CHAR'
  | 'ILLEGAL_QWERTY_SEQUENCE'
  | 'ILLEGAL_ALPHABETICAL_SEQUENCE'
  | 'ILLEGAL_NUMERICAL_SEQUENCE'
  | 'ILLEGAL_USERNAME'
  | 'ILLEGAL_USERNAME_CONTAINS'
  | 'ILLEGAL_USERNAME_STARTSWITH'
  | 'ILLEGAL_USERNAME_ENDSWITH'
  | 'ILLEGAL_USERNAME_REVERSED'
  | 'ILLEGAL_USERNAME_REVERSED_CONTAINS'
  | 'ILLEGAL_USERNAME_REVERSED_STARTSWITH'
  | 'ILLEGAL_USERNAME_REVERSED_ENDSWITH'
  | 'ILLEGAL_WHITESPACE'
  | 'ILLEGAL_WHITESPACE_CONTAINS'
  | 'ILLEGAL_WHITESPACE_STARTSWITH'
  | 'ILLEGAL_WHITESPACE_ENDSWITH'
  | 'ILLEGAL_NUMBER_RANGE'
  | 'ILLEGAL_NUMBER_RANGE_CONTAINS'
  | 'ILLEGAL_NUMBER_RANGE_STARTSWITH'
  | 'ILLEGAL_NUMBER_RANGE_ENDSWITH'
  | 'ILLEGAL_REPEATED_CHARS'
  | 'INSUFFICIENT_UPPERCASE'
  | 'INSUFFICIENT_LOWERCASE'
  | 'INSUFFICIENT_ALPHABETICAL'
  | 'INSUFFICIENT_DIGIT'
  | 'INSUFFICIENT_SPECIAL'
  | 'INSUFFICIENT_CHARACTERISTICS'
  | 'INSUFFICIENT_COMPLEXITY'
  | 'INSUFFICIENT_COMPLEXITY_RULES'
  | 'SOURCE_VIOLATION'
  | 'TOO_LONG'
  | 'TOO_SHORT'
  | 'TOO_MANY_OCCURRENCES'
  | 'MDNS.BTN.CLEAR'
  | 'MDNS.LGN.USERNAME'
  | 'MDNS.LGN.PASSWORD'
  | 'MDNS.BTN.LOGIN'
  | 'MDNS.RGSR.USERNAME'
  | 'MDNS.RGSR.HOSPITAL'
  | 'MDNS.RGSR.PASSWORD'
  | 'MDNS.RGSR.CONFIRMPASSWORD'
  | 'MDNS.BTN.REGISTER'
  | 'MDNS.CHGPWD.OLDPASSWORD'
  | 'MDNS.CHGPWD.NEWPASSWORD'
  | 'MDNS.CHGPWD.CONFIRMNEWPASSWORD'
  | 'MDNS.BTN.CHANGEPASSWORD'
  | 'MDNS.PRTCL.DATE'
  | 'MDNS.PRTCL.TIME'
  | 'MDNS.PRTCL.BED'
  | 'MDNS.PRTCL.PATIENTSTATUSCHANGE'
  | 'MDNS.PRTCL.BEDTRANSFERFROM'
  | 'MDNS.PRTCL.BEDTRANSFERTO'
  | 'MDNS.PRTCL.MEDICALASSISTANTINVOLVED'
  | 'MDNS.PRTCL.Q1'
  | 'MDNS.PRTCL.Q1A'
  | 'MDNS.PRTCL.Q1B'
  | 'MDNS.PRTCL.Q1C'
  | 'MDNS.PRTCL.Q2'
  | 'MDNS.PRTCL.Q3'
  | 'MDNS.PRTCL.Q4A'
  | 'MDNS.PRTCL.Q4B'
  | 'MDNS.PRTCL.Q4C'
  | 'MDNS.PRTCL.Q5'
  | 'MDNS.PRTCL.Q6'
  | 'MDNS.PRTCL.Q6A'
  | 'MDNS.PRTCL.Q6B'
  | 'MDNS.PRTCL.Q6C'
  | 'MDNS.PRTCL.Q7'
  | 'MDNS.PRTCL.Q7A'
  | 'MDNS.PRTCL.Q7B'
  | 'MDNS.PRTCL.Q8'
  | 'MDNS.PRTCL.Q8A'
  | 'MDNS.PRTCL.Q8B'
  | 'MDNS.PRTCL.Q8C'
  | 'MDNS.PRTCL.Q9'
  | 'MDNS.PRTCL.Q10'
  | 'MDNS.PRTCL.Q11'
  | 'MDNS.PRTCL.Q12'
  | 'MDNS.PRTCL.Q13'
  | 'MDNS.PRTCL.Q14'
  | 'MDNS.PRTCL.Q15'
  | 'MDNS.PRTCL.Q16'
  | 'MDNS.PRTCL.Q17'
  | 'MDNS.PRTCL.Q18'
  | 'MDNS.PRTCL.Q19'
  | 'MDNS.PRTCL.Q20'
  | 'MDNS.PRTCL.Q21'
  | 'MDNS.PRTCL.Q22'
  | 'MDNS.PRTCL.Q23'
  | 'MDNS.PRTCL.Q24'
  | 'MDNS.PRTCL.Q25'
  | 'MDNS.PRTCL.Q26'
  | 'MDNS.PRTCL.Q27'
  | 'MDNS.PRTCL.Q28'
  | 'MDNS.PRTCL.Q29'
  | 'MDNS.PRTCL.Q30'
  | 'MDNS.BTN.SAVEANDSUBMIT'
  | 'MDNS.PRTCLSMMR.HOSPITAL'
  | 'MDNS.PRTCLSMMR.DATEFROM'
  | 'MDNS.PRTCLSMMR.DATETO'
  | 'MDNS.PRTCLSMMR.EXPORTFORMAT'
  | 'MDNS.BTN.VIEWAGGRDATA'
  | 'MDNS.BTN.DOWNLOAD'
  | 'MDNS.BTN.LOADMORE'
  | 'MDNS.ARIA.LOADING'
  | 'MDNS.ARIA.SHOWHIDEPASSWORD'
  | 'MDNS.RPRT.PRTCLS.NAME'
  | 'MDNS.RPRT.MNPLTNSMMR.NAME'
  | 'MDNS.RPRT.PRTCLSMMR.NAME'
  | 'MDNS.RPRT.PRTCLSMMR.PATIENTSTATUS'
  | 'MDNS.MNPLTNSMMR.HOSPITAL'
  | 'MDNS.MNPLTNSMMR.DATEFROM'
  | 'MDNS.MNPLTNSMMR.DATETO'
  | 'MDNS.MNPLTNSMMR.EXPORTFORMAT'
  | 'MDNS.RPRT.MNPLTNSMMR.MANIPULATIONNO'
  | 'MDNS.RPRT.MNPLTNSMMR.NUMBEROFPOINTS'
  | 'MDNS.RPRT.MNPLTNSMMR.BEDNO'
  | 'MDNS.RPRT.MNPLTNSMMR.DATETIME'
  | 'MDNS.PRTCLS.HOSPITAL'
  | 'MDNS.PRTCLS.DATEFROM'
  | 'MDNS.PRTCLS.DATETO'
  | 'MDNS.PRTCLS.EXPORTFORMAT'
  | 'MDNS.BTN.VIEWDATA'
  | 'MDNS.RPRT.PRTCLS.DATETIME'
  | 'MDNS.RPRT.PRTCLS.BEDNO'
  | 'MDNS.RPRT.PRTCLS.TOTAL'
  | 'MDNS.ARIA.MENU';

export type CodeTableRef =
  | 'X.CT.HOSPITAL'
  | 'X.CT.TIME'
  | 'X.CT.BED'
  | 'X.CT.PATIENTSTATUSCHANGE'
  | 'X.CT.YESNO'
  | 'X.CT.EXPORTFORMAT';

export type CodeTableRefType = CodeTableRef | null;

export enum ServerTimeZone {
  UTC = 'UTC',
}
