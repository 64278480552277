import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSummary, HasFieldViolations } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { resetViolations, setViolations } from '../violations/violationsSlice';
import { GetSummaryParams, initialState, ProtocolSummary } from './index';

export const getSummaryThunk = createAsyncThunk<
  { protocolSummary: ProtocolSummary } & HasFieldViolations,
  GetSummaryParams
>('post/protocolSummary', async ({ keepExisting, ...input }, { dispatch }) => {
  const data = await getSummary(input);
  if (data.violations.length) {
    dispatch(setViolations({ violations: data.violations }));
  } else {
    dispatch(resetViolations());
    data.protocolSummary.keepExisting = keepExisting;
  }
  return data;
});

export const protocolSummarySlice = createSlice({
  name: 'protocolSummary',
  initialState,
  reducers: {
    resetProtocolSummary: (state) => {
      state = initialState;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSummaryThunk.fulfilled, (state, { payload: { violations, protocolSummary } }) => {
      if (!violations.length) {
        if (!protocolSummary.keepExisting) {
          state = protocolSummary;
        } else {
          state = {
            ...protocolSummary,
            summaries: [...state.summaries, ...protocolSummary.summaries],
          };
        }

        return state;
      }
    });
  },
});

export const selectProtocolSummary = (state: RootState) => state.protocolSummary;

export const { resetProtocolSummary } = protocolSummarySlice.actions;

export default protocolSummarySlice.reducer;
