import { RegisterRequest } from '../../../App/apiWrapper';
import { I18nTypes, ViolationLevel } from '../../../App/types';

export interface RegisterState extends RegisterRequest {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  violationArgs?: string[];
}

export const initialState: RegisterState = {
  userName: '',
  hospital: null,
  password: '',
  matchingPassword: '',
  violationLevel: null,
  violationId: null,
};
