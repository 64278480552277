import React, { FC } from 'react';
import { StyledTable } from './StyledTable';
import { ProtocolsTableHeader } from './ProtocolsTableHeader';
import { ProtocolsTableContent } from './ProtocolsTableContent';

export const ProtocolsTable: FC = () => {
  return (
    <StyledTable>
      <ProtocolsTableHeader />
      <ProtocolsTableContent />
    </StyledTable>
  );
};
