import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
// eslint-disable-next-line no-restricted-imports
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import { I18nTypes, RefIdType } from '../../App/types';
import { WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';
import { useTranslation } from '../../features/i18n/useTranslation';

export type FormSelectProps = SelectProps<RefIdType> &
  Partial<SelectInputProps<RefIdType>> &
  WithDisplayRuleProps & {
    id: I18nTypes;
    options: Array<{ id: string; name: string }>;
  };

export const FormSelectComponent: FC<FormSelectProps> = ({
  id,
  required,
  disabled,
  hasViolation,
  violationMessages,
  fullWidth,
  onChange,
  value,
  options,
  sx = {},
}) => {
  const helperTextItems = getHelpTextItems(violationMessages);

  const onClear = useCallback(() => {
    onChange && onChange({ target: { value: null, name: '' } } as SelectChangeEvent<RefIdType>, null);
  }, [onChange]);

  const handleMouseEvents = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const label = useTranslation(id);
  const labelId = `select_label_${id}`;
  const helperId = `select_helper_${id}`;
  const clearLabel = useTranslation('MDNS.BTN.CLEAR');

  const endAdornment = useMemo(
    () =>
      !required &&
      !disabled &&
      value && (
        <InputAdornment position="end" sx={{ position: 'absolute', right: 40 }}>
          <Tooltip title={clearLabel}>
            <span>
              <IconButton
                {...(clearLabel ? { 'aria-label': clearLabel } : {})}
                onClick={onClear}
                onMouseDown={handleMouseEvents}
                onMouseUp={handleMouseEvents}
              >
                <Clear fontSize={'small'} />
              </IconButton>
            </span>
          </Tooltip>
        </InputAdornment>
      ),
    [required, disabled, value, clearLabel, onClear, handleMouseEvents],
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      {...(hasViolation ? { error: true } : {})}
      required={required}
      sx={{ mt: '16px', mb: '8px', ...sx }}
    >
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        labelId={labelId}
        id={`select_${id}`}
        value={value || ''}
        label={label}
        aria-describedby={helperId}
        inputProps={{
          'data-testid': `select_${id}_input`,
          disabled,
        }}
        onChange={onChange}
        {...(endAdornment ? { endAdornment } : {})}
      >
        {options.map(({ id, name }) => (
          <MenuItem id={`select_menu_item_${id}`} key={`select_menu_item_${id}`} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText id={helperId}>{helperTextItems}</FormHelperText>
    </FormControl>
  );
};
