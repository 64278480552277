import React, { FC, useMemo } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectProtocolSummary } from '../../features/summary/protocolSummarySlice';
import { I18nFragment } from '../common/I18nFragment';

export const SummaryTableSubHeader: FC = () => {
  const { bedNames } = useAppSelector(selectProtocolSummary);
  const bedHeaders = useMemo(() => (bedNames.length > 0 ? bedNames : ['']), [bedNames]);

  return (
    <tr className="bold">
      <td>
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.DATETIME" />
      </td>
      {bedHeaders.map((bed, idx) => (
        <td id={`protocol_bed_hdr_${idx}`} key={`protocol_bed_hdr_${idx}`}>
          {bed}
        </td>
      ))}

      <td className="italic">
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.DAY" />
      </td>
      <td className="italic">
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.NIGHT" />
      </td>

      <td className="italic">
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.DAY" />
      </td>
      <td className="italic">
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.NIGHT" />
      </td>
      <td />
    </tr>
  );
};
