import React, { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../App/hooks';
import { selectUiState } from '../../features/ui/uiSlice';
import { useTranslation } from '../../features/i18n/useTranslation';

export const AppSpinner: FC = () => {
  const { showLoader } = useAppSelector(selectUiState);
  const ariaLoading = useTranslation('MDNS.ARIA.LOADING');

  if (!showLoader) {
    return null;
  }

  return (
    <Backdrop
      role="presentation"
      aria-hidden={false}
      sx={(theme) => ({
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={true}
    >
      <CircularProgress aria-label={ariaLoading} color="inherit" />
    </Backdrop>
  );
};
