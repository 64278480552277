import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch } from '../../App/hooks';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { withTranslations } from '../../features/i18n/withTranslations';
import { Question as QuestionType } from '../../features/protocol';
import { updateQuestion } from '../../features/protocol/protocolSlice';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';
import { FormRdrToggleButtonGroup } from '../common/FormRdrToggleButtonGroup';
import { useTranslation } from '../../features/i18n/useTranslation';

interface QuestionProps extends QuestionType, WithDisplayRuleProps {
  childQuestions?: QuestionType['children'];
}

const QuestionComponent: FC<QuestionProps> = ({
  id,
  childQuestions = [],
  answer,
  hasViolation,
  violationMessages,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (_, answer) => {
      answer && dispatch(updateQuestion({ answer, id }));
    },
    [id, dispatch],
  );
  const labelId = `question_label_${id}`;
  const helperId = `question_helper_${id}`;
  const helperTextItems = getHelpTextItems(violationMessages);

  return (
    <Paper
      role="region"
      aria-labelledby={labelId}
      sx={[
        {
          p: 3,
          my: 2,
          mx: 'auto',
          maxWidth: 1200,
          position: 'relative',
        },
        hasViolation ? { color: 'red' } : { color: '' },
        disabled ? { backgroundColor: 'lightGray' } : { backgroundColor: '' },
      ]}
      elevation={2}
    >
      <FormHelperText id={helperId} sx={{ position: 'absolute', top: 0 }} error>
        {helperTextItems}
      </FormHelperText>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography id={labelId} align="left">
          {useTranslation(id)}
        </Typography>
        {!childQuestions?.length && (
          <FormRdrToggleButtonGroup
            id={id}
            ariaLabeledBy={labelId}
            ariaDescribedBy={helperId}
            value={answer}
            onChange={handleChange}
            disabled={disabled}
            sx={{ maxHeight: 36 }}
          />
        )}
      </div>
      {childQuestions.map(({ id: childrenId, answer, ...rest }) => (
        <Question id={childrenId} key={childrenId} answer={answer} {...rest} />
      ))}
    </Paper>
  );
};

export const Question = withDisplayRule<QuestionProps>(
  withTranslations<QuestionProps>(memo<QuestionProps>(QuestionComponent)),
);
