import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HasFieldViolations } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { groupBy } from '../../helpers/groupBy';
import { changePasswordThunk } from '../user/changePassword/changePasswordSlice';
import { registerUserThunk } from '../user/register/registerSlice';
import { initialState, ViolationState } from './index';

const handleViolations = (state: ViolationState, action: PayloadAction<HasFieldViolations>) => {
  state.map = groupBy(action.payload.violations, 'fieldId');
};

export const violationsSlice = createSlice({
  name: 'violations',
  initialState,
  reducers: {
    setViolations: (state, action: PayloadAction<HasFieldViolations>) => {
      handleViolations(state, action);
    },
    resetViolations: (state, _: PayloadAction<void>) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        handleViolations(state, action);
      });
  },
});

export const selectViolationStateMap = (state: RootState) => state.violations.map;

export const selectViolations = createSelector(
  [selectViolationStateMap, (_: RootState, id: string) => id],
  (violationStateMap, id) => violationStateMap[id],
);

export const { setViolations, resetViolations } = violationsSlice.actions;

export default violationsSlice.reducer;
