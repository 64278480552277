import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { I18nTypes } from '../../App/types';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';
import { useTranslation } from '../../features/i18n/useTranslation';

export type FormTextFieldProps = TextFieldProps &
  WithDisplayRuleProps & {
    id: I18nTypes;
  };

export const FormTextFieldComponent: React.FC<FormTextFieldProps> = (props) => {
  const { id, hasViolation, maxLength, slotProps = {}, violationMessages, violationLevel, ...rest } = props;

  const { htmlInput, ...restSlotProps } = slotProps;

  const helperTextItems = getHelpTextItems(violationMessages);

  return (
    <TextField
      {...rest}
      label={useTranslation(id)}
      {...(hasViolation ? { error: true } : {})}
      helperText={<>{helperTextItems}</>}
      slotProps={{
        htmlInput: {
          ...htmlInput,
          ...(maxLength ? { maxLength } : {}),
        },
        ...restSlotProps,
      }}
    />
  );
};

export const FormTextField = withDisplayRule(FormTextFieldComponent);
