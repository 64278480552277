import React, { FC } from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nFragment } from '../common/I18nFragment';
import { selectManipulationSummary } from '../../features/summary/manipulationSummarySlice';

export const ManipulationSummaryTableHeader: FC = () => {
  const { manipulationNames } = useAppSelector(selectManipulationSummary);
  return (
    <tr>
      <th style={{ width: 120 }}></th>
      <th colSpan={manipulationNames.length}>
        <I18nFragment id="MDNS.RPRT.MNPLTNSMMR.MANIPULATIONNO" />
      </th>
      <th style={{ width: 100 }}></th>
      <th style={{ width: 100 }}></th>
    </tr>
  );
};
