import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nTypes, ServerTimeZone } from '../../App/types';
import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { selectUserProfileDateFormat } from '../../features/user/userSlice';
import { getHelpTextItems } from '../../features/violations/getHelpTextItems';
import { selectAppConfig } from '../../features/config/configSlice';
import { useTranslation } from '../../features/i18n/useTranslation';

type FormDatePickerPropsOnChange = FormDatePickerProps['onChange'];

interface FormDatePickerProps extends Omit<DatePickerProps<Dayjs>, 'value'>, WithDisplayRuleProps {
  fullWidth?: boolean;
  value: string | null;

  handleValueChange(newValue: string | null): void;
}
export const FormDatePickerComponent: FC<FormDatePickerProps> = ({
  id,
  value,
  fullWidth,
  handleValueChange,
  required,
  displayRuleIndexes,
  hasViolation,
  violationMessages,
  violationLevel,
  ...rest
}) => {
  const dateFormat = useAppSelector(selectUserProfileDateFormat);
  const { dateFormat: serverFormat, timeZone } = useAppSelector(selectAppConfig);
  const [stateValue, setStateValue] = useState<FormDatePickerProps['value']>(value);
  const [inputError, setInputError] = useState<string | null>(null);

  if (timeZone === ServerTimeZone.UTC) {
    dayjs.extend(utc);
  } else {
    throw new Error('time zone not supported');
  }

  const onChange: FormDatePickerPropsOnChange = useCallback(
    (newValue, { validationError }) => {
      setInputError(validationError ? validationError : null);
      setStateValue(newValue ? newValue.format(dateFormat) : null);
      handleValueChange(newValue ? dayjs.utc(newValue!.format(dateFormat)!, dateFormat).format(serverFormat!) : null);
    },
    [handleValueChange, dateFormat, serverFormat],
  );

  const helperTextItems = getHelpTextItems(violationMessages);

  const dateValue = useMemo(() => {
    if (stateValue) {
      return dayjs(stateValue, [dateFormat]);
    }

    return null;
  }, [stateValue, dateFormat]);

  return (
    <DatePicker
      {...rest}
      label={useTranslation(id)}
      format={dateFormat}
      value={dateValue}
      onChange={onChange}
      closeOnSelect
      slotProps={{
        textField: {
          fullWidth: fullWidth === true,
          variant: 'outlined',
          error: hasViolation,
          required,
          helperText: (
            <>
              {helperTextItems}
              {useTranslation(inputError as I18nTypes)}
            </>
          ),
        },
      }}
    />
  );
};

export const FormDatePicker = withDisplayRule(FormDatePickerComponent);
