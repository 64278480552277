import React, { FC } from 'react';
import { SummaryTableHeader } from './SummaryTableHeader';
import { SummaryTableSubHeader } from './SummaryTableSubHeader';
import { SummaryTableContent } from './SummaryTableContent';
import { StyledTable } from './StyledTable';

export const SummaryTable: FC = () => (
  <StyledTable>
    <thead>
      <SummaryTableHeader />
      <SummaryTableSubHeader />
    </thead>
    <SummaryTableContent />
  </StyledTable>
);
