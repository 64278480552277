import React, { FC, useMemo } from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nFragment } from '../common/I18nFragment';
import { selectManipulationSummary } from '../../features/summary/manipulationSummarySlice';

export const ManipulationSummaryTableSubHeader: FC = () => {
  const { manipulationNames } = useAppSelector(selectManipulationSummary);
  const manipulationHeaders = useMemo(
    () => (manipulationNames.length > 0 ? manipulationNames : ['']),
    [manipulationNames],
  );

  return (
    <tr className="bold">
      <td>
        <I18nFragment id="MDNS.RPRT.MNPLTNSMMR.DATETIME" />
      </td>
      {manipulationHeaders.map((man, idx) => (
        <td id={`protocol_man_hdr_${idx}`} key={`protocol_man_hdr_${idx}`}>
          {man}
        </td>
      ))}
      <th className="italic">
        <I18nFragment id="MDNS.RPRT.MNPLTNSMMR.BEDNO" />
      </th>
      <td className="italic">
        <I18nFragment id="MDNS.RPRT.MNPLTNSMMR.NUMBEROFPOINTS" />
      </td>
    </tr>
  );
};
