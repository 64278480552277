import React, { memo, useCallback, useMemo, useState } from 'react';
import { theme } from '../../App/theme';
import { FormRdrSelect } from '../common/FormRdrSelect';
import { FormDatePicker } from '../common/FormDatePicker';
import { FormButton } from '../common/FormButton';
import { SummaryDownload, SummaryDownloadProps } from '../summaryDownload/SummaryDownload';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTabContext } from '@mui/lab/TabContext';
import { SummaryTypes } from '../../App/types';
import { GetSummaryParams } from '../../features/summary';
import equal from 'fast-deep-equal/es6';

interface SummarySearchCommonProps extends Pick<SummaryDownloadProps, 'reportThunk'> {
  onClick(params): void;
  searchCriteria: Omit<GetSummaryParams, 'limit' | 'offset' | 'keepExisting'>;
}

type idReturnType = 'MDNS.MNPLTNSMMR' | 'MDNS.PRTCLSMMR' | 'MDNS.PRTCLS';

export const SummarySearchCommon = memo<SummarySearchCommonProps>(({ onClick, reportThunk, searchCriteria }) => {
  const { hospital, dateFrom: from, dateTo: to } = searchCriteria;
  const [selectedHospital, setSelectedHospital] = useState(hospital);
  const [dateTo, setDateTo] = useState<string | null>(to);
  const [dateFrom, setDateFrom] = useState<string | null>(from);

  const tabContext = useTabContext();

  const elementPrefix = useMemo<idReturnType>(() => {
    if (tabContext?.value === SummaryTypes.manipulations) {
      return 'MDNS.MNPLTNSMMR';
    }
    if (tabContext?.value === SummaryTypes.protocols) {
      return 'MDNS.PRTCLS';
    }

    return 'MDNS.PRTCLSMMR';
  }, [tabContext]);

  const handleHospitalChange = useCallback((event) => {
    setSelectedHospital(event.target.value);
  }, []);

  const handleDateFrom = useCallback((value) => {
    setDateFrom(value);
  }, []);

  const handleDateTo = useCallback((value) => {
    setDateTo(value);
  }, []);

  return (
    <>
      <Paper
        sx={{
          p: 3,
          width: '100%',
          mx: 'auto',
          maxWidth: 1200,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
        className="summary_generic_q"
        elevation={2}
      >
        <FormRdrSelect
          id={`${elementPrefix}.HOSPITAL`}
          onChange={handleHospitalChange}
          value={selectedHospital}
          sx={{
            minWidth: '20%',
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        />
        <FormDatePicker
          value={dateFrom}
          handleValueChange={handleDateFrom}
          id={`${elementPrefix}.DATEFROM`}
          views={['day']}
          sx={{
            [theme.breakpoints.down('md')]: {
              mt: 3,
              width: '100%',
            },
          }}
        />
        <FormDatePicker
          value={dateTo}
          handleValueChange={handleDateTo}
          id={`${elementPrefix}.DATETO`}
          views={['day']}
          sx={{
            [theme.breakpoints.down('md')]: {
              mt: 3,
              width: '100%',
            },
          }}
        />
        <FormButton
          id="MDNS.BTN.VIEWAGGRDATA"
          onClick={() => onClick({ hospital: selectedHospital, dateFrom, dateTo })}
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        />
        <FormButton
          id="MDNS.BTN.VIEWDATA"
          onClick={() => onClick({ hospital: selectedHospital, dateFrom, dateTo })}
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          }}
        />
      </Paper>

      <Box
        sx={{
          p: 0,
          width: '100%',
          mx: 'auto',
          maxWidth: 1200,
        }}
      >
        <SummaryDownload
          id={`${elementPrefix}.EXPORTFORMAT`}
          dateTo={dateTo}
          dateFrom={dateFrom}
          hospital={selectedHospital}
          reportThunk={reportThunk}
        />
      </Box>
    </>
  );
}, equal);
