import React, { FC } from 'react';
import { I18nFragment } from '../../common/I18nFragment';
import Typography from '@mui/material/Typography';

interface CountLabelProps {
  totalCount: number;
}

export const CountLabel: FC<CountLabelProps> = ({ totalCount }) => {
  return (
    <Typography variant="body1" sx={{ mb: 2 }}>
      <I18nFragment id="MDNS.RPRT.RECORDCOUNT" />: {totalCount}
    </Typography>
  );
};
