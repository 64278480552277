import React, { FC, useCallback } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectProtocols } from '../../features/summary/protocolsSlice';

export const ProtocolsTableContent: FC = () => {
  const { items, questionNames } = useAppSelector(selectProtocols);

  const getQuestionScore = useCallback(
    (order, score) => {
      if (items[order]?.questions[score] !== undefined) {
        return items[order]?.questions[score];
      }

      return '';
    },
    [items],
  );

  return (
    <tbody>
      {items.map(({ dateTime, total, bed, scaledTotal }, idx) => (
        <tr key={`protocol_bed_${idx}`}>
          <td className="bold">{dateTime}</td>
          <td className="result">{bed}</td>
          {questionNames.map((question, questionIdx) => (
            <td
              id={`protocol_question_${idx}_no_${questionIdx}`}
              key={`protocol_question_no_${questionIdx}`}
              className="bed_weight"
            >
              {getQuestionScore(idx, question)}
            </td>
          ))}
          <td>{`${total} = ${scaledTotal}`}</td>
        </tr>
      ))}
    </tbody>
  );
};
