import { ServerTimeZone } from '../../App/types';

export interface ConfigState {
  dateFormat: string | null;
  timeZone: ServerTimeZone | null;
  userRoleName: string | null;
  adminRoleName: string | null;
  initialized: boolean;
}

export const initialState: ConfigState = {
  dateFormat: null,
  timeZone: null,
  userRoleName: null,
  adminRoleName: null,
  initialized: false,
};
