import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { callChangePassword, ChangePasswordRequest, GenericOutput } from '../../../App/apiWrapper';
import { RootState } from '../../../App/store';
import { ViolationLevel } from '../../../App/types';
import { initialState } from './index';

export const changePasswordThunk = createAsyncThunk<GenericOutput, ChangePasswordRequest>(
  'changePassword/user',
  async (input) => await callChangePassword(input),
);

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    setOldPassword: (state, action: PayloadAction<string>) => {
      state.oldPassword = action.payload;
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.newPassword = action.payload;
    },
    setMatchingNewPassword: (state, action: PayloadAction<string>) => {
      state.matchingNewPassword = action.payload;
    },
    resetChangePassword: (state, _: PayloadAction<void>) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          state.violationLevel = ViolationLevel.INFO;
          state.violationId = 'MDNS.CHGPWD.IR.001';
          state.oldPassword = '';
          state.newPassword = '';
          state.matchingNewPassword = '';
        }
      })
      .addCase(changePasswordThunk.rejected, (state, _) => {
        state.violationLevel = ViolationLevel.ERROR;
        state.violationId = 'MDNS.COMMON.ER.001';
      });
  },
});

export const selectChangePassword = (state: RootState) => state.changePassword;

export const { setOldPassword, setNewPassword, setMatchingNewPassword, resetChangePassword } =
  changePasswordSlice.actions;

export default changePasswordSlice.reducer;
