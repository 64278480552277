import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { ProtocolState } from '../../features/protocol';
import { handleModalClose, selectProtocol, submitProtocolThunk } from '../../features/protocol/protocolSlice';
import { FormButton } from '../common/FormButton';
import { Modal } from '../common/Modal';
import { GeneralProtocolQuestions } from '../questions/GeneralProtocolQuestions';
import { Questions } from '../questions/Questions';
import { protocolWithRdrQuery } from './protocolWithRdrQuery';
import { withPageInit } from './withPageInit';

export const ProtocolComponent: FC = () => {
  const {
    bed,
    date,
    time,
    patientStatusChange,
    bedTransferFrom,
    bedTransferTo,
    medicalAssistantInvolved,
    questions,
    successModalOpen,
  } = useAppSelector<ProtocolState>(selectProtocol);
  const dispatch = useAppDispatch();

  const submitProtocol = () => {
    const submitDto = {
      date,
      time,
      bed,
      patientStatusChange,
      bedTransferFrom,
      bedTransferTo,
      medicalAssistantInvolved,
      questions,
    };
    dispatch(submitProtocolThunk(submitDto));
  };
  const handleClose = () => {
    dispatch(handleModalClose());
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid size={12}>
      <GeneralProtocolQuestions />
      <Questions />
      <Modal
        handleClose={handleClose}
        open={successModalOpen}
        contentId="MDNS.PRTCL.SUBMITTED"
        titleId="MDNS.COMMON.THANKYOU"
        actions={[{ labelId: 'MDNS.BTN.OK', onClick: handleClose }]}
      />
      <Box sx={{ maxWidth: '1200px', mx: 'auto' }}>
        <FormButton
          id="MDNS.BTN.SAVEANDSUBMIT"
          onClick={submitProtocol}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        />
      </Box>
    </Grid>
  );
};

export const ProtocolPage = protocolWithRdrQuery(withPageInit(ProtocolComponent));
