import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProtocol, HasFieldViolations, submitProtocol } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { RefIdType, ViolationLevel } from '../../App/types';
import { resetViolations, setViolations } from '../violations/violationsSlice';
import { moveChildren } from './moveChildren';
import { findQuestion } from './questionDeepSearch';
import { initialState, ProtocolState, Question, SubmitProtocolState } from './index';

export const submitProtocolThunk = createAsyncThunk<SubmitProtocolState & HasFieldViolations, SubmitProtocolState>(
  'submit/protocol',
  async (input, { dispatch }) => {
    const data = await submitProtocol(input);
    if (data.violations.length) {
      dispatch(setViolations({ violations: data.violations }));
    } else {
      dispatch(resetViolations());
    }
    return data;
  },
);

export const getProtocolThunk = createAsyncThunk<
  { protocol: ProtocolState } & HasFieldViolations,
  void,
  { state: RootState }
>('get/protocol', async (_, { getState }) => {
  const data = await getProtocol();

  if (!data.violations.length) {
    const currentState = getState();
    const questions = data.protocol.questions;

    const adjustedData = moveChildren(questions, currentState);

    return { protocol: { ...data.protocol, questionsDisplay: adjustedData }, violations: [] };
  }

  return data;
});

export const protocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    setProtocolDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setProtocolBed: (state, action: PayloadAction<RefIdType>) => {
      state.bed = action.payload;
    },
    setProtocolTime: (state, action: PayloadAction<RefIdType>) => {
      state.time = action.payload;
    },
    setPatientStatusChange: (state, action: PayloadAction<RefIdType>) => {
      state.patientStatusChange = action.payload;
    },
    setBedTransferFrom: (state, action: PayloadAction<RefIdType>) => {
      state.bedTransferFrom = action.payload;
    },
    setBedTransferTo: (state, action: PayloadAction<RefIdType>) => {
      state.bedTransferTo = action.payload;
    },
    setMedicalAssistantInvolved: (state, action: PayloadAction<RefIdType>) => {
      state.medicalAssistantInvolved = action.payload;
    },
    handleModalClose: (state) => {
      state.successModalOpen = false;
    },
    updateQuestion: (state, { payload }: PayloadAction<Question>) => {
      // we need to update both, display value & protocol value
      const question = findQuestion(state.questions, payload.id);
      const question2 = findQuestion(state.questionsDisplay, payload.id);
      question && (question.answer = payload.answer);
      question2 && (question2.answer = payload.answer);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProtocolThunk.fulfilled, (state, { payload: { protocol } }) => {
        Object.keys(protocol).forEach((key) => {
          state.initialProtocol[key] = protocol[key];
          state[key] = protocol[key];
        });
        state.violationLevel = null;
        state.violationId = null;
        state.successModalOpen = false;
        state.initialized = true;
      })
      .addCase(getProtocolThunk.rejected, (state) => {
        state.violationLevel = ViolationLevel.ERROR;
        state.violationId = 'MDNS.COMMON.ER.001';
      })
      .addCase(submitProtocolThunk.fulfilled, (state, { payload: { violations } }) => {
        if (!violations.length) {
          state.questions = state.initialProtocol.questions;
          state.questionsDisplay = state.initialProtocol.questionsDisplay;
          state.successModalOpen = true;
        }
      });
  },
});

export const selectProtocol = (state: RootState) => state.protocol;

export const selectProtocolInitialized = (state: RootState) => state.protocol.initialized;

export const {
  setProtocolDate,
  setProtocolBed,
  setProtocolTime,
  setPatientStatusChange,
  setBedTransferFrom,
  setBedTransferTo,
  setMedicalAssistantInvolved,
  updateQuestion,
  handleModalClose,
} = protocolSlice.actions;

export default protocolSlice.reducer;
