import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getSummaryThunk, selectProtocolSummary } from '../../features/summary/protocolSummarySlice';
import { SummarySearchCommon } from './SummarySearchCommon';
import { downloadReportThunk } from '../../features/summary/thunks/downloadReportThunk';

export const GeneralSummarySearch: FC = () => {
  const offset = 0;
  const dispatch = useAppDispatch();
  const { limit, searchCriteria } = useAppSelector(selectProtocolSummary);

  const queryData = ({ hospital, dateFrom, dateTo }) => {
    dispatch(
      getSummaryThunk({
        hospital,
        dateFrom,
        dateTo,
        offset,
        limit,
      }),
    );
  };

  return <SummarySearchCommon onClick={queryData} reportThunk={downloadReportThunk} searchCriteria={searchCriteria} />;
};
