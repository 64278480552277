import React, { FC, useCallback } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectProtocolSummary } from '../../features/summary/protocolSummarySlice';

export const SummaryTableContent: FC = () => {
  const { summaries, bedNames } = useAppSelector(selectProtocolSummary);

  const getBedResult = useCallback(
    (order, bed) => {
      if (summaries[order]?.beds[bed]) {
        return summaries[order]?.beds[bed];
      }

      return '';
    },
    [summaries],
  );

  const getNurses = useCallback((time, reqNurses, total, isDay, showDay): string => {
    if (((showDay && isDay) || (!showDay && !isDay)) && reqNurses) {
      return `${total} \n = \n ${reqNurses}`;
    }

    return '';
  }, []);

  return (
    <tbody>
      {summaries.map(({ dateTime, isDay, reqNurses, total, patientStatuses }, idx) => (
        <tr key={`protocol_bed_${idx}`}>
          <td className="bold">{dateTime}</td>
          {bedNames.map((bed, bedIdx) => (
            <td id={`protocol_bed_${idx}_no_${bedIdx}`} key={`protocol_bed_no_${bedIdx}`} className="bed_weight">
              {getBedResult(idx, bed)}
            </td>
          ))}

          <td></td>
          <td></td>

          <td className="result">{getNurses(dateTime, reqNurses, total, isDay, true)}</td>
          <td className="result">{getNurses(dateTime, reqNurses, total, isDay, false)}</td>
          <td className="result">{patientStatuses.join(',')}</td>
        </tr>
      ))}
    </tbody>
  );
};
