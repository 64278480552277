import React, { FC } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectManipulationSummary } from '../../features/summary/manipulationSummarySlice';
import { ManipulationsSummarySearch } from '../summarySearch/ManipulationsSummarySearch';
import { ManipulationSummaryTable } from '../summaryTable/ManipulationSummaryTable';
import { ManipulationSummaryLoadMore } from '../summarySearch/ManipulationSummaryLoadMore';
import { PrintButton } from './parts/PrintButton';
import { CountLabel } from './parts/CountLabel';
import { TabPanelPaper } from './parts/TabPanelPaper';

export const ManipulationSummaryContent: FC = () => {
  const { totalCount } = useAppSelector(selectManipulationSummary);

  return (
    <>
      <ManipulationsSummarySearch />
      <TabPanelPaper>
        <CountLabel totalCount={totalCount} />
        <ManipulationSummaryTable />
      </TabPanelPaper>
      <ManipulationSummaryLoadMore />
      <PrintButton totalCount={totalCount} />
    </>
  );
};
