import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nTypes, ViolationLevel } from '../../App/types';
import { i18n } from '../../features/i18n/i18n';
import { selectTranslationEntries } from '../../features/i18n/i18nSlice';

interface ViolationBannerProps {
  level: ViolationLevel;
  id?: I18nTypes | null;
  args?: string[];
}

const getSeverity = (violationLevel: ViolationLevel) => {
  switch (violationLevel) {
    case ViolationLevel.ERROR:
      return 'error';
    case ViolationLevel.WARNING:
      return 'warning';
    case ViolationLevel.INFO:
      return 'info';

    default:
      throw new Error(`Non-existent violationLevel in switch: ${violationLevel}`);
  }
};

export const ViolationBanner: React.FC<ViolationBannerProps> = (props) => {
  const translationEntries = useAppSelector(selectTranslationEntries);

  const { level, id, args } = props;

  const severity = getSeverity(level);
  const violationId = id ? id : 'MDNS.COMMON.ER.001';
  const violationMessage = i18n(violationId, translationEntries, ...(args || []));

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={severity}>{violationMessage}</Alert>
    </Stack>
  );
};
