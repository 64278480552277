import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FC, forwardRef, ReactElement, Ref } from 'react';
import { I18nTypes } from '../../App/types';
import { withTranslations } from '../../features/i18n/withTranslations';
import { useTranslation } from '../../features/i18n/useTranslation';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface ActionButtonProps {
  labelId: I18nTypes;
  onClick(): void;
}
interface ModalProps extends DialogProps {
  handleClose(): void;

  open: boolean;
  titleId: I18nTypes;
  contentId: I18nTypes;
  actions: ActionButtonProps[];
}

const ActionButton: FC<ActionButtonProps> = ({ labelId, onClick }) => (
  <Button onClick={onClick}>{useTranslation(labelId)}</Button>
);

const ModalComponent: FC<ModalProps> = ({ handleClose, open, titleId, contentId, actions }) => {
  const title = useTranslation(titleId);
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      slots={{
        transition: Transition,
      }}
      slotProps={{
        paper: { sx: { backgroundColor: '#d7f9d7' } },
      }}
    >
      {titleId && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{useTranslation(contentId)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(({ labelId, onClick }) => (
          <ActionButton labelId={labelId} onClick={onClick} key={labelId} />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export const Modal = withTranslations<ModalProps>(ModalComponent);
