import React, { FC, useCallback } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectManipulationSummary } from '../../features/summary/manipulationSummarySlice';

export const ManipulationSummaryTableContent: FC = () => {
  const { summaries, manipulationNames } = useAppSelector(selectManipulationSummary);

  const getManipulationsResult = useCallback(
    (order, bed) => {
      if (summaries[order]?.manipulations[bed]) {
        return summaries[order]?.manipulations[bed];
      }

      return '';
    },
    [summaries],
  );

  return (
    <tbody>
      {summaries.map(({ dateTime, total, beds }, idx) => (
        <tr key={`protocol_bed_${idx}`}>
          <td className="bold">{dateTime}</td>
          {manipulationNames.map((man, manIdx) => (
            <td id={`protocol_man_${idx}_no_${manIdx}`} key={`protocol_man_no_${manIdx}`} className="bed_weight">
              {getManipulationsResult(idx, man)}
            </td>
          ))}

          <td className="result">{beds.join(', ')}</td>
          <td className="result">{total}</td>
        </tr>
      ))}
    </tbody>
  );
};
