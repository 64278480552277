import { storageService } from './StorageService';

export const USER_AUTH_STATE = 'user-auth-state';

class AuthService {
  resetAuthState = (): void => {
    storageService.setValue(USER_AUTH_STATE, null);
  };

  createAuthToken = (username: string, password: string): string => {
    return 'Basic ' + window.btoa(username + ':' + password);
  };
}

export const authService = new AuthService();
