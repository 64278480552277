import React, { FC } from 'react';
import { SummaryLoadMore } from '../summarySearch/SummaryLoadMore';
import { useAppSelector } from '../../App/hooks';
import { selectProtocolSummary } from '../../features/summary/protocolSummarySlice';
import { SummaryTable } from '../summaryTable/SummaryTable';
import { GeneralSummarySearch } from '../summarySearch/GeneralSummarySearch';
import { PrintButton } from './parts/PrintButton';
import { CountLabel } from './parts/CountLabel';
import { TabPanelPaper } from './parts/TabPanelPaper';

export const ProtocolSummaryContent: FC = () => {
  const { totalCount } = useAppSelector(selectProtocolSummary);
  return (
    <>
      <GeneralSummarySearch />
      <TabPanelPaper>
        <CountLabel totalCount={totalCount} />
        <SummaryTable />
      </TabPanelPaper>
      <SummaryLoadMore />
      <PrintButton totalCount={totalCount} />
    </>
  );
};
