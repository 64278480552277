import PasswordIcon from '@mui/icons-material/Password';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import {
  changePasswordThunk,
  resetChangePassword,
  selectChangePassword,
  setMatchingNewPassword,
  setNewPassword,
  setOldPassword,
} from '../../features/user/changePassword/changePasswordSlice';
import { FormButton } from '../common/FormButton';
import { FormTypography } from '../common/FormTypography';
import { ViolationBanner } from '../common/ViolationBanner';
import { withPageInit } from './withPageInit';
import { FormPasswordField } from '../common/FormPasswordField';

const ChangePasswordPageComponent = () => {
  const dispatch = useAppDispatch();
  const changePasswordState = useAppSelector(selectChangePassword);
  const navigate = useNavigate();

  const { oldPassword, newPassword, matchingNewPassword, violationLevel, violationId, violationArgs } =
    changePasswordState;

  useEffect(() => {
    dispatch(resetChangePassword());
  }, [navigate, dispatch]);

  const doChangePassword = () => {
    const passwordDto = {
      oldPassword,
      newPassword,
      matchingNewPassword,
    };
    dispatch(changePasswordThunk(passwordDto));
  };

  const handleOldPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setOldPassword(event.target.value));
  };

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setNewPassword(event.target.value));
  };

  const handleMatchingNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setMatchingNewPassword(event.target.value));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {violationLevel && <ViolationBanner level={violationLevel} id={violationId} args={violationArgs} />}
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <PasswordIcon />
        </Avatar>
        <FormTypography id="MDNS.PG.CHANGEPASSWORD" component="h1" variant="h5" />
        <Box sx={{ mt: 1 }}>
          <FormPasswordField
            id="MDNS.CHGPWD.OLDPASSWORD"
            margin="normal"
            fullWidth
            onChange={handleOldPasswordChange}
            value={oldPassword}
          />
          <FormPasswordField
            id="MDNS.CHGPWD.NEWPASSWORD"
            margin="normal"
            fullWidth
            onChange={handleNewPasswordChange}
            value={newPassword}
          />
          <FormPasswordField
            id="MDNS.CHGPWD.CONFIRMNEWPASSWORD"
            margin="normal"
            fullWidth
            onChange={handleMatchingNewPasswordChange}
            value={matchingNewPassword}
          />
          <FormButton
            id="MDNS.BTN.CHANGEPASSWORD"
            onClick={doChangePassword}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export const ChangePasswordPage = withPageInit(ChangePasswordPageComponent);
