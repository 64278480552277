import React, { FC } from 'react';
import { FormButtonComponent } from '../../common/FormButton';

interface PrintButtonProps {
  totalCount: number;
}

export const PrintButton: FC<PrintButtonProps> = ({ totalCount }) => {
  return (
    <>
      {!!totalCount && (
        <FormButtonComponent
          id="MDNS.BTN.PRINT"
          fullWidth
          onClick={window.print}
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
          }}
        />
      )}
    </>
  );
};
