import { createAsyncThunk } from '@reduxjs/toolkit';
import { DownloadSummaryParams } from '../index';
import { downloadManipulationSummary } from '../../../App/apiWrapper';
import { prepareReportThunk } from './prepareReportThunk';

export const downloadManipulationReportThunk = createAsyncThunk<void, DownloadSummaryParams>(
  'post/exportManipulations',
  async ({ ...input }, { dispatch }) => {
    const response = await downloadManipulationSummary(input);
    dispatch(prepareReportThunk(response));
  },
);
