import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getReferenceDataMap, ReferenceDataMap } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { ScreenRef } from '../../App/types';
import { selectRdrMapKey } from '../ui/uiSlice';
import { parseReferenceData } from './parseReferenceData';
import { initialState, RefDataState } from './index';

export const getReferenceDataMapThunk = createAsyncThunk<ReferenceDataMap, { screenRef: ScreenRef; rdrQuery: string }>(
  'refdata/referencedatamap',
  async ({ screenRef, rdrQuery }) => await getReferenceDataMap(screenRef, rdrQuery),
);

const handleReferenceDataMap = (action: PayloadAction<ReferenceDataMap>, state: RefDataState) => {
  const { screen, entries } = action.payload;
  state.screenRefDataMap[screen] = parseReferenceData(entries);
};

export const refdataSlice = createSlice({
  name: 'refdata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReferenceDataMapThunk.fulfilled, (state, action) => {
      handleReferenceDataMap(action, state);
    });
  },
});

export const selectScreenRefDataMap = (state: RootState) => state.refdata.screenRefDataMap;

export const selectRefDataInitialized = createSelector(
  [selectScreenRefDataMap, selectRdrMapKey],
  (selectScreenRefDataMap, key) => (key ? !!selectScreenRefDataMap[key] : null),
);

export default refdataSlice.reducer;
