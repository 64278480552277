import Grid from '@mui/material/Grid2';
import React, { FC, useState } from 'react';

import { withPageInit } from './withPageInit';
import { useTranslation } from '../../features/i18n/useTranslation';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { ManipulationSummaryContent } from '../summaryContent/ManipulationSummaryContent';
import { ProtocolSummaryContent } from '../summaryContent/ProtocolSummaryContent';
import { useLocation, useNavigate } from 'react-router';
import { SummaryTypes } from '../../App/types';
import { ProtocolsContent } from '../summaryContent/ProtocolsContent';
import { useGetSelectedSummaryTab } from '../../hooks/useGetSelectedSummaryTab';

const SummaryPageComponent: FC = () => {
  const { pathname } = useLocation();
  const [tab, setTab] = useState<SummaryTypes>(useGetSelectedSummaryTab(pathname));
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: SummaryTypes) => {
    setTab(newValue);
    navigate(`/dashboard/${newValue}`);
  };

  return (
    <Grid size={12} role="region" aria-label={useTranslation('MDNS.PG.SUMMARY')}>
      <TabContext value={tab}>
        <Box
          sx={{
            width: '100%',
            maxWidth: 1200,
            mx: 'auto',
            typography: 'body1',
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList onChange={handleChange} aria-label="report types">
            <Tab label={useTranslation('MDNS.RPRT.PRTCLSMMR.NAME')} value={SummaryTypes.summary} />
            <Tab label={useTranslation('MDNS.RPRT.MNPLTNSMMR.NAME')} value={SummaryTypes.manipulations} />
            <Tab label={useTranslation('MDNS.RPRT.PRTCLS.NAME')} value={SummaryTypes.protocols} />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value={SummaryTypes.summary}>
          <ProtocolSummaryContent />
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value={SummaryTypes.manipulations}>
          <ManipulationSummaryContent />
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value={SummaryTypes.protocols}>
          <ProtocolsContent />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export const SummaryPage = withPageInit(SummaryPageComponent);
