import { useState } from 'react';
import { storageService } from '../services/StorageService';
import { StringType } from '../App/types';

export const useLocalStorage = (keyName: string, defaultValue: StringType = null) => {
  const [storedValue, setStoredValue] = useState(() => {
    return storageService.getValue(keyName, defaultValue);
  });

  const setValue = (newValue) => {
    storageService.setValue(keyName, newValue);
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
