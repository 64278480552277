import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetLogin } from '../loginSlice';
import { resetProtocolSummary } from '../../../summary/protocolSummarySlice';
import { resetManipulationSummary } from '../../../summary/manipulationSummarySlice';
import { resetProtocols } from '../../../summary/protocolsSlice';

export const performLogoutThunk = createAsyncThunk('logout', async (_, { dispatch }) => {
  dispatch(resetLogin());

  // clearing data only on logout action
  dispatch(resetProtocolSummary());
  dispatch(resetManipulationSummary());
  dispatch(resetProtocols());
});
