import React, { ComponentType, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getTranslationMapThunk, selectTranslationsInitialized } from './i18nSlice';

export const withTranslations = <T extends object>(WrappedComponent: ComponentType<T>) => {
  const ComponentWithTranslations = (props) => {
    const dispatch = useAppDispatch();
    const translationsInitialized = useAppSelector(selectTranslationsInitialized);

    useEffect(() => {
      if (!translationsInitialized) {
        dispatch(getTranslationMapThunk());
      }
    }, [dispatch, translationsInitialized]);

    if (!translationsInitialized) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
  ComponentWithTranslations.displayName = `withTranslations(
    ${WrappedComponent.displayName || WrappedComponent.name || 'Component'}
  )`;

  return ComponentWithTranslations;
};
