import React, { FC, useCallback, useState } from 'react';
import { FormButtonComponent } from '../common/FormButton';
import { useAppDispatch } from '../../App/hooks';
import { DownloadSummaryParams } from '../../features/summary';
import { theme } from '../../App/theme';
import { AsyncThunk } from '@reduxjs/toolkit';
import { I18nTypes, SummaruesExportType } from '../../App/types';
import { FormRadioGroup } from '../common/FormRadioGroup';
import Paper from '@mui/material/Paper';

export interface SummaryDownloadProps extends Omit<DownloadSummaryParams, 'exportFormat'> {
  reportThunk: AsyncThunk<void, DownloadSummaryParams, {}>;
  id: I18nTypes;
}

export const SummaryDownload: FC<SummaryDownloadProps> = ({ reportThunk, id, ...props }) => {
  const dispatch = useAppDispatch();
  const [exportFormat, setFormat] = useState<SummaruesExportType>(SummaruesExportType.EXCEL);

  const downloadReport = () => {
    dispatch(reportThunk({ ...props, exportFormat }));
  };
  const changeFormat = useCallback((event) => setFormat(event.target.value), []);

  return (
    <Paper
      sx={{
        p: 3,
        width: '100%',
        mx: 'auto',
        mt: 3,
        maxWidth: 1200,
        alignItems: 'baseline',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
      className="summary_generic_q"
      elevation={2}
    >
      <FormRadioGroup id={id} name="exportType" onChange={changeFormat} value={exportFormat} inline />
      <FormButtonComponent
        id="MDNS.BTN.DOWNLOAD"
        onClick={downloadReport}
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }}
      />
    </Paper>
  );
};
