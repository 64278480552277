import { SpelExpressionEvaluator } from 'spel2js';
import { Data, SIZE_SUFFIX } from '../index';

export const sizeOf = (data: Data, attribute: string): number => {
  if (data) {
    const keys = Object.keys(data);
    if (keys.includes(attribute) && Array.isArray(data[attribute])) {
      const expression = `${attribute}${SIZE_SUFFIX}`;
      return SpelExpressionEvaluator.eval(expression, data, []);
    }
  }
  return -1;
};
