import Cookies from 'js-cookie';
import { startLoader, stopLoader } from '../features/ui/uiSlice';
import { csrfInfoSingleton } from '../helpers/csrf/csrf';
import { authService } from '../services/AuthService';
import { store } from './store';

export const doGet = async (endpoint: RequestInfo, navigateToLoginOnAuthError: boolean = true) => {
  return doFetch(endpoint, 'GET', null, navigateToLoginOnAuthError);
};

export const doPost = async <T,>(
  endpoint: RequestInfo,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
) => {
  return doFetch(endpoint, 'POST', request, navigateToLoginOnAuthError, authTokenProvider);
};

export const doDownload = async <T extends {}>(
  endpoint: RequestInfo,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
) => doFetch<T>(endpoint, 'POST', request, navigateToLoginOnAuthError, authTokenProvider, true);

export const doPut = async <T,>(endpoint: RequestInfo, request: T) => {
  return doFetch(endpoint, 'PUT', request);
};

export const doDelete = async (endpoint: RequestInfo) => {
  return doFetch(endpoint, 'DELETE');
};

const addAuthorizationHeader = (headers, authTokenProvider?): void => {
  if (authTokenProvider) {
    const authToken = authTokenProvider();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
  }
};

const addCsrfToken = (headers): void => {
  const { csrfInfo } = csrfInfoSingleton;
  const csrfToken = Cookies.get(csrfInfo.cookieName!);
  if (csrfToken) {
    headers.append(csrfInfo.headerName!, csrfToken);
  }
};

let numberOfCalls = 0;
let timeout;
const doFetch = async <T,>(
  endpoint: RequestInfo,
  method: string,
  request?: T,
  navigateToLoginOnAuthError: boolean = true,
  authTokenProvider?,
  isFile?: boolean,
) => {
  const dispatch = store.dispatch;
  if (numberOfCalls === 0) {
    dispatch(startLoader());
  }
  clearTimeout(timeout);

  numberOfCalls++;
  const headers = new Headers();
  headers.append('content-type', 'application/json');
  addAuthorizationHeader(headers, authTokenProvider);
  addCsrfToken(headers);

  const options: RequestInit = {
    method,
    headers,
  };
  if (request) {
    options.body = JSON.stringify(request);
  }

  const handleResponse = (response) => {
    numberOfCalls--;
    if (numberOfCalls === 0) {
      // adding 500ms to reduce loader jumping between calls.
      timeout = setTimeout(() => dispatch(stopLoader()), 500);
    }
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authService.resetAuthState();
        if (navigateToLoginOnAuthError) {
          window.history.pushState({}, '', '/login');
          window.location.reload();
        }
      } else {
        numberOfCalls = 0;
        dispatch(stopLoader());
        throw new Error(response.statusText);
      }
    }

    if (isFile) {
      return response;
    }

    return response.json();
  };

  return fetch(endpoint, options).then(handleResponse);
};
