import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProtocols, HasFieldViolations } from '../../../App/apiWrapper';
import { GetSummaryParams } from '../index';
import { resetViolations, setViolations } from '../../violations/violationsSlice';
import { Protocols } from '../protocolsSlice';

export const getProtocolsThunk = createAsyncThunk<{ protocols: Protocols } & HasFieldViolations, GetSummaryParams>(
  'post/protocols',
  async ({ keepExisting, ...input }, { dispatch }) => {
    const data = await getProtocols(input);
    if (data.violations.length) {
      dispatch(setViolations({ violations: data.violations }));
    } else {
      dispatch(resetViolations());
      data.protocols.keepExisting = keepExisting;
    }

    return data;
  },
);
