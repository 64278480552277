import Typography from '@mui/material/Typography';
import React from 'react';

export const getHelpTextItems = (violationMessages?: string[]): React.ReactElement[] => {
  const helperTextItems: React.ReactElement[] = [];
  if (violationMessages) {
    violationMessages.forEach((value, index) =>
      helperTextItems.push(
        <Typography component="span" key={index} aria-label={value} sx={{ fontSize: '0.75rem', display: 'block' }}>
          {value}
        </Typography>,
      ),
    );
  }
  return helperTextItems;
};
