import React, { FC, PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';

export const TabPanelPaper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper
      className="summary_generic_table"
      sx={{ overflowX: 'auto', whiteSpace: 'nowrap', p: 3, my: 5, mx: 'auto' }}
      elevation={2}
    >
      {children}
    </Paper>
  );
};
