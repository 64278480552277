import { CodeTableRef } from '../../App/types';
import { ReferenceData } from '../../App/apiWrapper';

export type RefData = {
  [key in CodeTableRef]?: ReferenceData[];
};

export type ScreenRefDataMap = {
  [key in string]?: RefData;
};

export interface RefDataState {
  screenRefDataMap: ScreenRefDataMap;
}

export const initialState: RefDataState = {
  screenRefDataMap: {},
};
