import React, { FC, useCallback } from 'react';
import { FormButton } from '../common/FormButton';
import { getSummaryThunk, selectProtocolSummary } from '../../features/summary/protocolSummarySlice';
import { useAppDispatch, useAppSelector } from '../../App/hooks';

export const SummaryLoadMore: FC = () => {
  const dispatch = useAppDispatch();
  const { limit, offset, searchCriteria, totalCount } = useAppSelector(selectProtocolSummary);

  const loadMore = useCallback(() => {
    dispatch(
      getSummaryThunk({
        ...searchCriteria,
        offset: offset + limit,
        limit,
        keepExisting: true,
      }),
    );
  }, [dispatch, offset, limit, searchCriteria]);

  if (offset + limit >= totalCount) {
    return null;
  }

  return (
    <FormButton
      id="MDNS.BTN.LOADMORE"
      onClick={loadMore}
      fullWidth
      variant="contained"
      sx={{
        mt: 3,
        mb: 2,
        display: 'block',
      }}
    />
  );
};
