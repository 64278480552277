import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { withDisplayRule } from '../../features/displayRules/withDisplayRule';
import { selectRefDataList } from '../../features/refdata/selectRefDataList';
import { FormSelectComponent, FormSelectProps } from './FormSelect';

export type FormRdrSelectComponentProps = Omit<FormSelectProps, 'options' | 'multiple' | 'autoWidth' | 'native'>;

const FormRdrSelectComponent = (props) => {
  const options = useAppSelector((state) => selectRefDataList(state, props.id));
  return <FormSelectComponent {...props} options={options} />;
};

export const FormRdrSelect = withDisplayRule(FormRdrSelectComponent);
