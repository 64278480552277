import { UserProfile } from '../../App/apiWrapper';

export interface UserState {
  profile: UserProfile;
  initialized: boolean;
}

export const initialState: UserState = {
  profile: {
    user: null,
    hospitalName: null,
    locale: 'lv',
    dateFormat: 'DD/MM/YYYY',
  },
  initialized: false,
};
