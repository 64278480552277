import React from 'react';
import { Navigate, useOutlet } from 'react-router';
import { useAuth } from '../../hooks/useAuth';
import { AppBar } from './AppBar';

interface HomeLayoutProps {
  authUserRoute: string;
}

export const HomeLayout: React.FC<HomeLayoutProps> = ({ authUserRoute }) => {
  const { userAuthenticated } = useAuth();
  const outlet = useOutlet();

  if (userAuthenticated) {
    return <Navigate to={authUserRoute} replace />;
  }

  return (
    <div>
      <AppBar pages={[{ id: 'MDNS.PG.LOGIN', path: '/login' }]} />
      {outlet}
    </div>
  );
};
