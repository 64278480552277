import { I18nTypes, RefIdType, ViolationLevel } from '../../App/types';

export interface Question {
  answer: RefIdType;
  children?: Omit<Question, 'children'>[];
  id: I18nTypes;
}

interface Protocol {
  bed: RefIdType;
  time: RefIdType;
  date: string | null;
  patientStatusChange: RefIdType;
  bedTransferFrom: RefIdType;
  bedTransferTo: RefIdType;
  medicalAssistantInvolved: RefIdType;
  questions: Question[];
  questionsDisplay: Question[];
}

export interface ProtocolState extends Protocol {
  violationLevel?: ViolationLevel | null;
  violationId?: I18nTypes | null;
  successModalOpen: boolean;
  initialProtocol: Protocol;
  initialized: boolean;
}

export interface SubmitProtocolState extends Omit<Protocol, 'questionsDisplay'> {}

const initialProtocol: Protocol = {
  bed: null,
  time: null,
  date: null,
  patientStatusChange: null,
  bedTransferFrom: null,
  bedTransferTo: null,
  medicalAssistantInvolved: null,
  questions: [],
  questionsDisplay: [],
};

export const initialState: ProtocolState = {
  ...initialProtocol,
  violationLevel: null,
  violationId: null,
  successModalOpen: false,
  initialProtocol: {
    ...initialProtocol,
  },
  initialized: false,
};
