import React, { FC } from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectProtocolSummary } from '../../features/summary/protocolSummarySlice';
import { I18nFragment } from '../common/I18nFragment';

export const SummaryTableHeader: FC = () => {
  const { bedNames } = useAppSelector(selectProtocolSummary);
  return (
    <tr>
      <th style={{ width: 120 }}></th>
      <th colSpan={bedNames.length}>
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.BEDNO" />
      </th>
      <th style={{ width: 100 }} colSpan={2}>
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.NURSESNOCUR" />
      </th>
      <th style={{ width: 100 }} colSpan={2}>
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.NURSESNOREQ" />
      </th>
      <th style={{ width: 100 }}>
        <I18nFragment id="MDNS.RPRT.PRTCLSMMR.PATIENTSTATUS" />
      </th>
    </tr>
  );
};
