import { Question } from './index';
import { RootState } from '../../App/store';
import { i18n } from '../i18n/i18n';

export const moveChildren = (arrayOfObjects: Question[], state: RootState) => {
  const newObjects: Question[] = [];
  const entries = state.i18n.entries;

  arrayOfObjects.forEach((obj) => {
    const { id, children } = obj;
    const translation = i18n(id, entries);
    if ((translation === id || translation.trim().length === 0) && children) {
      newObjects.push(...children);
    } else {
      newObjects.push(obj);
    }
  });

  return newObjects;
};
