import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line no-restricted-imports
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { RefIdType } from '../../App/types';
import { ProtocolState } from '../../features/protocol';
import {
  selectProtocol,
  setBedTransferFrom,
  setBedTransferTo,
  setMedicalAssistantInvolved,
  setPatientStatusChange,
  setProtocolBed,
  setProtocolDate,
  setProtocolTime,
} from '../../features/protocol/protocolSlice';
import { FormDatePicker } from '../common/FormDatePicker';
import { FormRdrSelect } from '../common/FormRdrSelect';
import { selectUserProfile } from '../../features/user/userSlice';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { FormRadioGroup } from '../common/FormRadioGroup';
import { RadioGroupProps } from '@mui/material/RadioGroup';
import { theme } from '../../App/theme';
import FormLabel from '@mui/material/FormLabel';
import { I18nFragment } from '../common/I18nFragment';

export const GeneralProtocolQuestions: FC = () => {
  const dispatch = useAppDispatch();
  const { bed, date, time, patientStatusChange, bedTransferFrom, bedTransferTo, medicalAssistantInvolved } =
    useAppSelector<ProtocolState>(selectProtocol);
  const { hospitalName } = useAppSelector(selectUserProfile);

  const handleDateChange = (value) => {
    dispatch(setProtocolDate(value));
  };

  const handleBedChange: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setProtocolBed(event.target.value));
  };

  const handleTimeChange: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setProtocolTime(event.target.value));
  };

  const handlePatientStatusChange: RadioGroupProps['onChange'] = (event) => {
    dispatch(setPatientStatusChange(event.target.value));
  };

  const handleBedTransferFrom: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setBedTransferFrom(event.target.value));
  };

  const handleBedTransferTo: SelectInputProps<RefIdType>['onChange'] = (event) => {
    dispatch(setBedTransferTo(event.target.value));
  };

  const handleMedicalAssistantInvolved: RadioGroupProps['onChange'] = (event) => {
    dispatch(setMedicalAssistantInvolved(event.target.value));
  };

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Paper sx={{ p: 3, my: 5, mx: 'auto', maxWidth: 1200 }} elevation={2}>
      {hospitalName && (
        <Typography sx={{ mb: 1 }} variant="h5">
          {hospitalName}
        </Typography>
      )}
      <FormDatePicker
        value={date}
        fullWidth
        handleValueChange={handleDateChange}
        id="MDNS.PRTCL.DATE"
        views={['day']}
      />

      <FormRdrSelect id="MDNS.PRTCL.BED" fullWidth name="bed" onChange={handleBedChange} value={bed} />
      <FormRdrSelect id="MDNS.PRTCL.TIME" fullWidth name="time" onChange={handleTimeChange} value={time} />

      <FormRadioGroup
        id="MDNS.PRTCL.PATIENTSTATUSCHANGE"
        name="time"
        onChange={handlePatientStatusChange}
        value={patientStatusChange}
      />

      <Box sx={{ mt: 1, mb: 1 }}>
        <FormLabel>
          <I18nFragment id="MDNS.PRTCL.PATIENTTRANSFER" />
        </FormLabel>
        <Box sx={{ mt: 1.5 }}>
          <Stack direction={matches ? 'row' : 'column'} spacing={2}>
            <FormRdrSelect
              id="MDNS.PRTCL.BEDTRANSFERFROM"
              fullWidth
              name="bedTransferFrom"
              onChange={handleBedTransferFrom}
              value={bedTransferFrom}
            />
            <FormRdrSelect
              id="MDNS.PRTCL.BEDTRANSFERTO"
              fullWidth
              name="bedTransferTo"
              onChange={handleBedTransferTo}
              value={bedTransferTo}
            />
          </Stack>
        </Box>
      </Box>

      <FormRadioGroup
        id="MDNS.PRTCL.MEDICALASSISTANTINVOLVED"
        name="medicalAssistantInvolved"
        onChange={handleMedicalAssistantInvolved}
        value={medicalAssistantInvolved}
      />
    </Paper>
  );
};
