import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { SummarySearchCommon } from './SummarySearchCommon';
import { selectProtocols } from '../../features/summary/protocolsSlice';
import { getProtocolsThunk } from '../../features/summary/thunks/getProtocolsThunk';
import { downloadProtocolsReportThunk } from '../../features/summary/thunks/downloadProtocolsReportThunk';

export const ProtocolsSearch: FC = () => {
  const offset = 0;
  const dispatch = useAppDispatch();
  const { limit, searchCriteria } = useAppSelector(selectProtocols);

  const queryData = ({ hospital, dateFrom, dateTo }) => {
    dispatch(
      getProtocolsThunk({
        hospital,
        dateFrom,
        dateTo,
        offset,
        limit,
      }),
    );
  };

  return (
    <SummarySearchCommon
      onClick={queryData}
      reportThunk={downloadProtocolsReportThunk}
      searchCriteria={searchCriteria}
    />
  );
};
