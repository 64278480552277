import { createAsyncThunk } from '@reduxjs/toolkit';
import { getManipulationsSummary, HasFieldViolations } from '../../../App/apiWrapper';
import { resetViolations, setViolations } from '../../violations/violationsSlice';
import { GetSummaryParams } from '../index';
import { ManipulationsSummary } from '../manipulationSummarySlice';

export const getManipulationsSummaryThunk = createAsyncThunk<
  { manipulationSummary: ManipulationsSummary } & HasFieldViolations,
  GetSummaryParams
>('post/manipulationSummary', async ({ keepExisting, ...input }, { dispatch }) => {
  const data = await getManipulationsSummary(input);
  if (data.violations.length) {
    dispatch(setViolations({ violations: data.violations }));
  } else {
    dispatch(resetViolations());
    data.manipulationSummary.keepExisting = keepExisting;
  }

  return data;
});
