import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAppConfig } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { ConfigState, initialState } from './index';

export const getAppConfigThunk = createAsyncThunk<ConfigState>('app/config', async () => await getAppConfig());

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppConfigThunk.fulfilled, (state, action) => {
      state = action.payload;
      state.initialized = true;

      return state;
    });
  },
});

export const selectAppConfig = (state: RootState) => state.config;

export const selectAppConfigInitialized = (state: RootState) => state.config.initialized;

export default config.reducer;
