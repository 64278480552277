import React from 'react';
import { useAppSelector } from '../../App/hooks';
import { selectRefDataList } from '../../features/refdata/selectRefDataList';
import { FormToggleButtonGroup, FormToggleButtonGroupProps } from './FormToggleButtonGroup';

export interface FormRdrToggleButtonGroupProps extends Omit<FormToggleButtonGroupProps, 'options'> {}

export const FormRdrToggleButtonGroup: React.FC<FormRdrToggleButtonGroupProps> = (props) => {
  const options = useAppSelector((state) => selectRefDataList(state, props.id)) || [];
  return <FormToggleButtonGroup {...props} options={options} />;
};
