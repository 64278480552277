import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { SummarySearchCommon } from './SummarySearchCommon';
import { getManipulationsSummaryThunk } from '../../features/summary/thunks/getManipulationsSummary';
import { selectManipulationSummary } from '../../features/summary/manipulationSummarySlice';
import { downloadManipulationReportThunk } from '../../features/summary/thunks/downloadManipulationReportThunk';

export const ManipulationsSummarySearch: FC = () => {
  const offset = 0;
  const dispatch = useAppDispatch();
  const { limit, searchCriteria } = useAppSelector(selectManipulationSummary);

  const queryData = ({ hospital, dateFrom, dateTo }) => {
    dispatch(
      getManipulationsSummaryThunk({
        hospital,
        dateFrom,
        dateTo,
        offset,
        limit,
      }),
    );
  };

  return (
    <SummarySearchCommon
      onClick={queryData}
      reportThunk={downloadManipulationReportThunk}
      searchCriteria={searchCriteria}
    />
  );
};
