import React, { FC, useMemo } from 'react';
import { useAppSelector } from '../../App/hooks';
import { I18nFragment } from '../common/I18nFragment';
import { selectProtocols } from '../../features/summary/protocolsSlice';

export const ProtocolsTableHeader: FC = () => {
  const { questionNames } = useAppSelector(selectProtocols);
  const questionHeaders = useMemo(() => (questionNames.length > 0 ? questionNames : ['']), [questionNames]);
  return (
    <thead>
      <tr>
        <th style={{ width: 130 }}>
          <I18nFragment id="MDNS.RPRT.MNPLTNSMMR.DATETIME" />
        </th>
        <th style={{ width: 70 }}>
          <I18nFragment id="MDNS.RPRT.PRTCLS.BEDNO" />
        </th>
        {questionHeaders.map((question, idx) => (
          <th id={`protocol_question_hdr_${idx}`} key={`protocol_question_hdr_${idx}`}>
            {question}
          </th>
        ))}
        <th style={{ width: 100 }}>
          <I18nFragment id="MDNS.RPRT.PRTCLS.TOTAL" />
        </th>
      </tr>
    </thead>
  );
};
