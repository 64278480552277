import { I18nTypes } from '../../App/types';

export type Translations = {
  [key in I18nTypes]?: string;
};

export interface I18nState {
  entries?: Translations;
  initialized: boolean;
}

export const initialState: I18nState = {
  initialized: false,
};
