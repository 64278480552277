import Typography, { TypographyProps } from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import Box from '@mui/material/Box';
import React from 'react';
import { I18nTypes } from '../../App/types';
import { useTranslation } from '../../features/i18n/useTranslation';

type FormTypographyProps = TypographyProps & {
  component?: React.ElementType;
  id: I18nTypes;
  boxSx?: SxProps;
};

export const FormTypography: React.FC<FormTypographyProps> = (props) => {
  const { id, boxSx, ...rest } = props;

  return (
    <Typography {...rest}>
      <Box sx={boxSx}>{useTranslation(id)}</Box>
    </Typography>
  );
};
