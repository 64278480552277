import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserProfile, UserProfile } from '../../App/apiWrapper';
import { RootState } from '../../App/store';
import { initialState } from './index';

export const getUserProfileThunk = createAsyncThunk<UserProfile>('user/profile', async () => await getUserProfile());

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfileThunk.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.initialized = true;
    });
  },
});

export const { resetUser } = userSlice.actions;

export const selectUserInitialized = (state: RootState) => state.user.initialized;

export const selectUserProfile = (state: RootState) => state.user.profile;

export const selectUserRoles = (state: RootState) => state.user.profile?.user?.roles || [];

export const selectUserProfileDateFormat = (state: RootState) => state.user.profile.dateFormat;

export const selectUserHospital = (state: RootState) =>
  state.user.profile.user ? state.user.profile.user.hospital : null;

export default userSlice.reducer;
