import React, { FC } from 'react';
import { StyledTable } from './StyledTable';
import { ManipulationSummaryTableHeader } from './ManipulationSummaryTableHeader';
import { ManipulationSummaryTableSubHeader } from './ManipulationSummaryTableSubHeader';
import { ManipulationSummaryTableContent } from './ManipulationSummaryTableContent';

export const ManipulationSummaryTable: FC = () => {
  return (
    <StyledTable>
      <thead>
        <ManipulationSummaryTableHeader />
        <ManipulationSummaryTableSubHeader />
      </thead>
      <ManipulationSummaryTableContent />
    </StyledTable>
  );
};
