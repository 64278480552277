import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { callLogin, callLogout } from '../App/apiWrapper';
import { useAppDispatch } from '../App/hooks';
import { ViolationLevel } from '../App/types';
import { resetViolation, setViolation } from '../features/user/login/loginSlice';
import { resetUser } from '../features/user/userSlice';
import { resetViolations, setViolations } from '../features/violations/violationsSlice';
import { authService, USER_AUTH_STATE } from '../services/AuthService';
import { useLocalStorage } from './useLocalStorage';
import { AuthContext, AuthState } from './AuthContext';
import { performLogoutThunk } from '../features/user/login/thunks/performLogoutThunk';

export const USER_AUTH_STATE_AUTHENTICATED = 'authenticated';

interface AuthProviderProps {
  authUserRoute: string;
  anonymousUserRoute: string;
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ authUserRoute, anonymousUserRoute, children }) => {
  const [userAuthState, setUserAuthState] = useLocalStorage(USER_AUTH_STATE, null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const login = useCallback(
    async (data: AuthState) => {
      try {
        dispatch(resetViolations());
        dispatch(resetViolation());
        const authToken = authService.createAuthToken(data.userName, data.password);
        const response = await callLogin(authToken);
        const { violations } = response;
        if (violations && violations.length > 0) {
          dispatch(setViolations(response));
        } else {
          setUserAuthState(USER_AUTH_STATE_AUTHENTICATED);
          dispatch(performLogoutThunk());
          dispatch(resetUser());
          navigate(authUserRoute, { replace: true });
        }
      } catch (err) {
        dispatch(
          setViolation({
            violationLevel: ViolationLevel.ERROR,
            ruleId: 'MDNS.AUTH.ER.001',
            args: [],
          }),
        );
      }
    },
    [setUserAuthState, navigate, authUserRoute, dispatch],
  );

  const logout = useCallback(async () => {
    setUserAuthState(null);
    await callLogout();
    navigate(anonymousUserRoute, { replace: true });
  }, [setUserAuthState, navigate, anonymousUserRoute]);

  const value = useMemo(
    () => ({
      userAuthenticated: userAuthState === USER_AUTH_STATE_AUTHENTICATED,
      login,
      logout,
    }),
    [userAuthState, login, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
