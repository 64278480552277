import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { GetSummaryParams } from './index';
import { getProtocolsThunk } from './thunks/getProtocolsThunk';

interface ProtocolItem {
  bed: string;
  dateTime: string;
  questions: {
    [question: string]: number;
  };
  scaledTotal: number;
  total: number;
}

export interface Protocols {
  searchCriteria: Omit<GetSummaryParams, 'limit' | 'offset'>;
  hospital: string;
  questionNames: string[];
  offset: number;
  limit: number;
  totalCount: number;
  items: ProtocolItem[];
  keepExisting?: boolean;
}

export const initialState: Protocols = {
  hospital: '',
  limit: 25,
  questionNames: [],
  offset: 0,
  items: [],
  totalCount: 0,
  searchCriteria: {
    hospital: null,
    dateFrom: null,
    dateTo: null,
  },
};

export const protocolsSlice = createSlice({
  name: 'protocols',
  initialState,
  reducers: {
    resetProtocols: (state) => {
      state = initialState;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProtocolsThunk.fulfilled, (state, { payload: { violations, protocols } }) => {
      if (!violations.length) {
        if (!protocols.keepExisting) {
          state = protocols;
        } else {
          state = {
            ...protocols,
            items: [...state.items, ...protocols.items],
          };
        }

        return state;
      }
    });
  },
});

export const selectProtocols = (state: RootState) => state.protocols;

export const { resetProtocols } = protocolsSlice.actions;

export default protocolsSlice.reducer;
