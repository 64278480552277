import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate } from 'react-router';
import useMediaQuery from '@mui/system/useMediaQuery';
import { useAppDispatch } from '../../App/hooks';
import { I18nTypes } from '../../App/types';
import { useAuth } from '../../hooks/useAuth';
import { FormButtonComponent } from '../common/FormButton';
import { FormTypography } from '../common/FormTypography';
import { UserAccountInfo } from './UserAccountInfo';
import { useTranslation } from '../../features/i18n/useTranslation';
import { theme } from '../../App/theme';
import { performLogoutThunk } from '../../features/user/login/thunks/performLogoutThunk';

export interface Page {
  id: I18nTypes;
  path: string;
}

interface AppBarProps {
  pages: Page[];
}

export const AppBar: React.FC<AppBarProps> = ({ pages }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { userAuthenticated, logout } = useAuth();
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  const handleLogout = () => {
    dispatch(performLogoutThunk());
    logout();
  };

  const menuLabel = useTranslation('MDNS.ARIA.MENU');

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiAppBar position="static" className="app_bar_root">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {!matches && (
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {pages.length > 0 && (
                <>
                  <IconButton
                    size="large"
                    aria-label={menuLabel}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page.id} onClick={handleClose} component={Link} to={page.path}>
                        <FormTypography id={page.id} component="div" textAlign="center" />
                      </MenuItem>
                    ))}
                    {userAuthenticated && (
                      <MenuItem key={'logout'} onClick={handleLogout}>
                        <FormTypography id="MDNS.PG.LOGOUT" component="div" textAlign="center" />
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </Box>
          )}
          <FormTypography
            id="MDNS.HEADER"
            variant="h6"
            noWrap
            component="div"
            sx={[{ display: 'flex' }, matches ? { mr: 2 } : { flexGrow: 1 }]}
            {...(matches ? { boxSx: { textTransform: 'uppercase' } } : {})}
          />
          {matches && (
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {pages.map((page) => (
                <FormButtonComponent
                  id={page.id}
                  key={page.id}
                  onClick={() => handleNavigate(page.path)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                />
              ))}
              {userAuthenticated && (
                <FormButtonComponent
                  id="MDNS.PG.LOGOUT"
                  key={'logout'}
                  onClick={handleLogout}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                />
              )}
            </Box>
          )}
          {userAuthenticated && <UserAccountInfo />}
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
};
