import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { getProtocolThunk, selectProtocolInitialized } from '../../features/protocol/protocolSlice';
import { selectUserHospital } from '../../features/user/userSlice';

export const protocolWithRdrQuery = (WrappedComponent) => (props) => {
  const dispatch = useAppDispatch();
  const userHospital = useAppSelector(selectUserHospital);
  const initialized = useAppSelector(selectProtocolInitialized);
  const rdrQuery = `?hospital=${userHospital || ''.replace(/"/g, '')}`;

  useEffect(() => {
    if (!initialized) {
      dispatch(getProtocolThunk());
    }
  }, [dispatch, initialized]);

  if (!initialized) {
    return null;
  }

  return <WrappedComponent {...props} rdrQuery={rdrQuery} />;
};
