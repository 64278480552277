import { createAsyncThunk } from '@reduxjs/toolkit';
import { DownloadSummaryParams } from '../index';
import { downloadSummary } from '../../../App/apiWrapper';
import { prepareReportThunk } from './prepareReportThunk';

export const downloadReportThunk = createAsyncThunk<void, DownloadSummaryParams>(
  'post/export',
  async ({ ...input }, { dispatch }) => {
    const response = await downloadSummary(input);
    dispatch(prepareReportThunk(response));
  },
);
