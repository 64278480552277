import React, { FC, useCallback } from 'react';
import { FormButton } from '../common/FormButton';
import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { selectProtocols } from '../../features/summary/protocolsSlice';
import { getProtocolsThunk } from '../../features/summary/thunks/getProtocolsThunk';

export const ProtocolsLoadMore: FC = () => {
  const dispatch = useAppDispatch();
  const { limit, offset, searchCriteria, totalCount } = useAppSelector(selectProtocols);

  const loadMore = useCallback(() => {
    dispatch(
      getProtocolsThunk({
        ...searchCriteria,
        offset: offset + limit,
        limit,
        keepExisting: true,
      }),
    );
  }, [dispatch, offset, limit, searchCriteria]);

  if (offset + limit >= totalCount) {
    return null;
  }

  return (
    <FormButton
      id="MDNS.BTN.LOADMORE"
      onClick={loadMore}
      fullWidth
      variant="contained"
      sx={{
        mt: 3,
        mb: 2,
        display: 'block',
      }}
    />
  );
};
