import { MapEntries } from '../../App/types';
import {
  DisplayRuleDefinition,
  FieldMetadataDefinition,
  FieldMetadataMap,
  FieldStateDefinition,
  FieldStateMap,
} from '../../App/apiWrapper';

export interface DisplayRuleState {
  defaultState: MapEntries<FieldStateDefinition> | null;
  currentState?: FieldStateMap | null;
  metadata: MapEntries<FieldMetadataDefinition> | null;
  currentMetadata?: FieldMetadataMap | null;
  displayRuleDefs: DisplayRuleDefinition[] | null;
}

export type DisplayRuleStateMap = {
  [key in string]: DisplayRuleState;
};

export const initialState: DisplayRuleStateMap = {};
