import { RefIdType } from '../../App/types';

interface Summary {
  dateTime: string;
  isDay: boolean;
  total: number;
  reqNurses: number;
  beds: {
    [bed: string]: number;
  };
  patientStatuses: string[];
}

export interface ProtocolSummary {
  dataAccurate: boolean;
  hospital: string;
  limit: number;
  bedNames: string[];
  offset: number;
  summaries: Summary[];
  totalCount: number;
  searchCriteria: Omit<GetSummaryParams, 'limit' | 'offset' | 'keepExisting'>;
  keepExisting?: boolean;
}

export const initialState: ProtocolSummary = {
  dataAccurate: false,
  hospital: '',
  limit: 25,
  bedNames: [],
  offset: 0,
  summaries: [],
  totalCount: 0,
  searchCriteria: {
    hospital: null,
    dateFrom: null,
    dateTo: null,
  },
};

export interface DownloadSummaryParams {
  hospital: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  offset?: number;
  limit?: number;
  exportFormat: RefIdType;
}

export interface GetSummaryParams {
  hospital: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  offset: number;
  limit: number;
  keepExisting?: boolean;
}
