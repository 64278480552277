import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React, { FC } from 'react';
import { AppRoutes } from './AppRoutes';
import { theme } from './theme';
import { AppSpinner } from '../components/common/AppSpinner';
import { BrowserRouter } from 'react-router';

export const App: FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <AppSpinner />
  </ThemeProvider>
);
