import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetViolations, setViolations } from '../../violations/violationsSlice';

export const prepareReportThunk = createAsyncThunk<void, Response>('post/export', async (response, { dispatch }) => {
  const contentType = response.headers.get('Content-Type');

  if (contentType?.includes('application/json')) {
    const content = await response.json();
    if (content.violations.length) {
      dispatch(setViolations({ violations: content.violations }));
    }
  } else {
    dispatch(resetViolations());

    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded-file';

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match.length > 1) {
        filename = match[1];
      }
    }

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  }
});
